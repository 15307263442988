import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import SearchForm from "../../../cards/utilities/searchForm";

const CompletedCourses = () => {
    const [expandedRow, setExpandedRow] = useState(null);

    const toggleRow = (rowIndex) => {
        setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
    };

    const rows = [
        { id: 1, firstName: "Mark", lastName: "Otto", username: "@mdo" },
        { id: 2, firstName: "Jacob", lastName: "Thornton", username: "@fat" },
        {
            id: 3,
            firstName: "Larry the Bird",
            lastName: "",
            username: "@twitter",
        },
    ];

    return (
        <section className="container">
            <section className="card p-4 mb-8">
                <header>
                    <h5>Completed Tracks</h5>
                    <p>Review all your transaction history</p>

                    <section className="w-100 mb-4">
                        <SearchForm placeholder="Search Transactions" />
                    </section>
                </header>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Course</th>
                            <th className="d-none d-sm-table-cell">Track</th>
                            <th className="d-none d-sm-table-cell">
                                Payment Date
                            </th>
                            <th className="d-none d-sm-table-cell">Amount</th>
                            <th></th>{" "}
                            {/* Add a new header for the expand/collapse arrow */}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <React.Fragment key={row.id}>
                                <tr>
                                    <td>{row.id}</td>
                                    <td>{row.firstName}</td>
                                    <td className="d-none d-sm-table-cell">
                                        {row.lastName}
                                    </td>
                                    <td className="d-none d-sm-table-cell">
                                        {row.username}
                                    </td>
                                    <td className="d-none d-sm-table-cell">
                                        {row.username}
                                    </td>
                                    <td
                                        onClick={() => toggleRow(index)}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        {/* Render the arrow icon conditionally */}
                                        {expandedRow === index ? (
                                            <FaChevronUp />
                                        ) : (
                                            <FaChevronDown />
                                        )}
                                    </td>
                                </tr>
                                {expandedRow === index && (
                                    <tr>
                                        <td colSpan="5">
                                            <div>
                                                {/* Replace this with whatever content you want to display */}
                                                <p>
                                                    Additional details for{" "}
                                                    {row.firstName}{" "}
                                                    {row.lastName}.
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </section>
            <section className="card p-4">
                <header>
                    <h5>Completed Courses</h5>
                    <p>Review all your transaction history</p>

                    <section className="w-100 mb-4">
                        <SearchForm placeholder="Search Transactions" />
                    </section>
                </header>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Course</th>
                            <th className="d-none d-sm-table-cell">Track</th>
                            <th className="d-none d-sm-table-cell">
                                Payment Date
                            </th>
                            <th className="d-none d-sm-table-cell">Amount</th>
                            <th></th>{" "}
                            {/* Add a new header for the expand/collapse arrow */}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <React.Fragment key={row.id}>
                                <tr>
                                    <td>{row.id}</td>
                                    <td>{row.firstName}</td>
                                    <td className="d-none d-sm-table-cell">
                                        {row.lastName}
                                    </td>
                                    <td className="d-none d-sm-table-cell">
                                        {row.username}
                                    </td>
                                    <td className="d-none d-sm-table-cell">
                                        {row.username}
                                    </td>
                                    <td
                                        onClick={() => toggleRow(index)}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        {/* Render the arrow icon conditionally */}
                                        {expandedRow === index ? (
                                            <FaChevronUp />
                                        ) : (
                                            <FaChevronDown />
                                        )}
                                    </td>
                                </tr>
                                {expandedRow === index && (
                                    <tr>
                                        <td colSpan="5">
                                            <div>
                                                {/* Replace this with whatever content you want to display */}
                                                <p>
                                                    Additional details for{" "}
                                                    {row.firstName}{" "}
                                                    {row.lastName}.
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </section>
        </section>
    );
};

export default CompletedCourses;
