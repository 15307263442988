import { useState } from "react";

const SearchForm = ({ onSearch, placeholder }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        // onSearch(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearch(searchTerm);
        }
    };
    return (
        <section>
            <form>
                <div className="form-floating">
                    <input
                        type="text"
                        class="form-control"
                        id="search"
                        placeholder={placeholder}
                        name="search"
                        onChange={(e) => handleSearchChange(e)}
                        value={searchTerm}
                        onKeyDown={handleKeyPress}
                        required
                    />
                    <label for="search">{placeholder}</label>
                </div>
            </form>
        </section>
    );
};

export default SearchForm;
