import HeaderBar from "../components/headerBar";
import Nav from "react-bootstrap/Nav";
import { useState } from "react";
import ProfileInformation from "./ProfileInfo";
import ChangePassword from "./changePassword";

const Profile = () => {
    const [activeTab, setActiveTab] = useState("#profile-info");

    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    return (
        <section>
            <HeaderBar pageHeader="Profile">
                <Nav
                    variant="pills"
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    className=""
                >
                    <Nav.Item className="border border-2 border-primary me-2">
                        <Nav.Link eventKey="#profile-info">Profile</Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="border border-2 border-primary">
                        <Nav.Link eventKey="#change-password">
                            Change Password
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </HeaderBar>

            <section className="container">
                {activeTab === "#profile-info" && <ProfileInformation />}

                {activeTab === "#change-password" && <ChangePassword />}
            </section>
        </section>
    );
};

export default Profile;
