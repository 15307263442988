import useAuthicatedUser from "../../../hooks/useLoadUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const apiURL = process.env.REACT_APP_API_URL;

const AuthChecker = ({ children }) => {
    const location = useLocation();
    // Check Auth and Load user
    const { isAuthenticated } = useAuthicatedUser(apiURL, location);

    // const from = localStorage.getItem("from") || "/student/dashboard";
    // const back = window.history.back();

    // const navigate = useNavigate();
    // useEffect(() => {
    //     if (isAuthenticated) {
    //         navigate(back, { replace: true });
    //     }
    // }, [isAuthenticated, navigate]);
    return <section>{children}</section>;
};

export default AuthChecker;
