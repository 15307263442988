import { useContext, useState } from "react";
import { userInfo } from "../topBar";
import axios from "axios";

const pic = {
    url: "http://www.highreshdwallpapers.com/wp-content/uploads/2014/01/Athletic-Running-HD-Image.jpg",
};
const ProfileInformation = () => {
    const currentUser = useContext(userInfo);

    const [loading, setLoading] = useState(false);

    const [isEditMode, setIsEditMode] = useState(false);

    // Update Profile Pic
    const [profilePicFile, setProfilePicFile] = useState([]);
    const [previewUrl, setPreviewUrl] = useState(null);

    // Update Profile Pic input
    const onProfilePicChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePicFile(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleEditToggle = () => {
        setIsEditMode(!isEditMode);
    };

    const handleFormReset = () => {
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
        }
        setProfilePicFile(null);
        setPreviewUrl(null);
        setIsEditMode(false);
        setFormData({
            first_name: currentUser?.first_name,
            last_name: currentUser?.last_name,
            phone_number: currentUser?.phone_number,
            date_of_birth: currentUser?.date_of_birth,
            height: currentUser?.height,
            weight: currentUser?.weight,
            gender: currentUser?.gender,
            sport: currentUser?.middle_name,
            home_address: currentUser?.home_address,
            local_govt: currentUser?.local_govt,
            state_of_origin: currentUser?.state_of_origin,
            nationality: currentUser?.nationality,
            image: currentUser?.image,
            get_photo_url: currentUser?.get_photo_url,
        });

        // Clear the file input value
        document.getElementById("inputGroupFile02").value = "";
    };

    //Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        first_name: currentUser?.first_name,
        last_name: currentUser?.last_name,
        phone_number: currentUser?.phone_number,
        date_of_birth: currentUser?.date_of_birth,
        height: currentUser?.height,
        weight: currentUser?.weight,
        gender: currentUser?.gender,
        sport: currentUser?.middle_name,
        home_address: currentUser?.home_address,
        local_govt: currentUser?.local_govt,
        state_of_origin: currentUser?.state_of_origin,
        nationality: currentUser?.nationality,
        image: currentUser?.image,
        get_photo_url: currentUser?.get_photo_url,
    });

    const {
        first_name,
        last_name,
        phone_number,
        date_of_birth,
        height,
        weight,
        middle_name,
        home_address,
        local_govt,
        state_of_origin,
        nationality,
    } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    // Select Input
    const [selectedOption, setSelectedOption] = useState("");

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        // declare the data Submit function
        const submitData = async () => {
            if (localStorage.getItem("access")) {
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `JWT ${localStorage.getItem("access")}`,
                        Accept: "application/json",
                    },
                };

                const formData = new FormData();
                formData.append("first_name", first_name);
                formData.append("last_name", last_name);
                formData.append("phone_number", phone_number);
                formData.append("date_of_birth", date_of_birth);
                formData.append("height", height);
                formData.append("weight", weight);
                formData.append("gender", selectedOption);
                formData.append("middle_name", middle_name);
                formData.append("home_address", home_address);
                formData.append("local_govt", local_govt);
                formData.append("state_of_origin", state_of_origin);
                formData.append("nationality", nationality);
                formData.append("image", profilePicFile);

                try {
                    const res = await axios.put(
                        `${process.env.REACT_APP_API_URL}/auth/users/me/`,
                        formData,
                        config
                    );
                    if (res.status === 200) {
                        handleShow();
                        setLoading(false);
                        // Refresh User Info
                        // fetchUserData();
                    }
                } catch (err) {
                    console.error("User not authenticated");
                    setLoading(false);
                }
            } else {
                console.error("User not authenticated");
                setLoading(false);
            }
        };

        // submitData();
    };
    return (
        <section className="container">
            <section className="row ">
                <div class="card auth-card no-card-style py-8 px-4 px-lg-8 col-lg-10 mx-auto">
                    <section className="row g-3">
                        <section className="d-lg-flex justify-content-center align-items-center position-relative text-center">
                            <section className="position-relative d-inline-block">
                                <img
                                    src={previewUrl ? previewUrl : pic.url}
                                    className="rounded-circle me-4"
                                    width="150"
                                    height="150"
                                    alt={`${currentUser?.first_name} ${currentUser?.last_name}`}
                                />
                                {isEditMode && (
                                    <section
                                        className="text-primary text-center position-absolute bg-light rounded-circle p-1"
                                        style={{
                                            bottom: "15px",
                                            right: "15px",
                                            width: "40px",
                                            height: "40px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            document
                                                .getElementById(
                                                    "inputGroupFile02"
                                                )
                                                .click()
                                        }
                                    >
                                        <i class="fa-solid fa-camera fs-6"></i>
                                    </section>
                                )}

                                <div class="col-12 input-group mb-3">
                                    <input
                                        type="file"
                                        class="form-control d-none"
                                        id="inputGroupFile02"
                                        name="image"
                                        accept="image/*"
                                        onChange={(e) => onProfilePicChange(e)}
                                    />
                                    <label
                                        class="input-group-text d-none"
                                        for="inputGroupFile02"
                                    >
                                        Upload
                                    </label>
                                </div>
                            </section>

                            <section>
                                <h6>{`${currentUser?.first_name} ${currentUser?.last_name}`}</h6>
                                <p>{currentUser?.email}</p>
                            </section>
                            {!isEditMode && (
                                <section
                                    className="position-absolute bg-danger rounded-circle p-1 text-center"
                                    style={{
                                        top: "0",
                                        right: "0",
                                        width: "40px",
                                        height: "40px",
                                        cursor: "pointer",
                                    }}
                                    onClick={handleEditToggle}
                                >
                                    <i class="fa-regular fa-pen-to-square text-white fs-6"></i>
                                </section>
                            )}
                        </section>

                        <form onSubmit={(e) => onSubmit(e)}>
                            <section className="row g-3">
                                <section className="col-12 mt-5">
                                    <header className="text-center">
                                        <h5>Personal Details</h5>
                                        <p>
                                            Review and edit your personal
                                            information.
                                        </p>
                                    </header>
                                </section>
                                <div class="col-md-6">
                                    {isEditMode ? (
                                        <section className="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="first_name"
                                                placeholder="First Name"
                                                name="first_name"
                                                onChange={(e) => onChange(e)}
                                                value={first_name}
                                                required
                                            />
                                            <label for="first_name">
                                                First Name
                                            </label>
                                        </section>
                                    ) : (
                                        <section>
                                            <strong>First Name: </strong>
                                            <p>
                                                {first_name ? first_name : "--"}
                                            </p>
                                        </section>
                                    )}
                                </div>
                                <div class="col-md-6">
                                    {isEditMode ? (
                                        <section className="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="middle_name"
                                                placeholder="First Name"
                                                name="middle_name"
                                                onChange={(e) => onChange(e)}
                                                value={middle_name}
                                                required
                                            />
                                            <label for="middle_name">
                                                Middle Name
                                            </label>
                                        </section>
                                    ) : (
                                        <section>
                                            <strong>Middle Name: </strong>
                                            <p>
                                                {middle_name
                                                    ? middle_name
                                                    : "--"}
                                            </p>
                                        </section>
                                    )}
                                </div>
                                <div class="col-md-6">
                                    {isEditMode ? (
                                        <section className="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="last_name"
                                                placeholder="First Name"
                                                name="last_name"
                                                onChange={(e) => onChange(e)}
                                                value={last_name}
                                                required
                                            />
                                            <label for="last_name">
                                                Last Name
                                            </label>
                                        </section>
                                    ) : (
                                        <section>
                                            <strong>Last Name: </strong>
                                            <p>
                                                {last_name ? last_name : "--"}
                                            </p>
                                        </section>
                                    )}
                                </div>
                                <div class="col-md-6">
                                    {isEditMode ? (
                                        <section className="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="phone_number"
                                                placeholder="First Name"
                                                name="phone_number"
                                                onChange={(e) => onChange(e)}
                                                value={phone_number}
                                                required
                                            />
                                            <label for="phone_number">
                                                Phone Number
                                            </label>
                                        </section>
                                    ) : (
                                        <section>
                                            <strong>Phone Number: </strong>
                                            <p>
                                                {phone_number
                                                    ? phone_number
                                                    : "--"}
                                            </p>
                                        </section>
                                    )}
                                </div>

                                <div className="col-md-6">
                                    {isEditMode ? (
                                        <div class="form-floating">
                                            <select
                                                class="form-select"
                                                id="floatingSelect"
                                                aria-label="Floating label select example"
                                                value={
                                                    selectedOption === ""
                                                        ? formData.gender
                                                        : selectedOption
                                                }
                                                onChange={handleOptionChange}
                                            >
                                                <option selected>
                                                    -- Select --
                                                </option>
                                                <option value="Male">
                                                    Male
                                                </option>
                                                <option value="Female">
                                                    Female
                                                </option>
                                            </select>
                                            <label for="floatingSelect">
                                                Gender
                                            </label>
                                        </div>
                                    ) : (
                                        <section>
                                            <strong>Gender: </strong>
                                            <p>
                                                {formData.gender
                                                    ? formData.gender
                                                    : "--"}
                                            </p>
                                        </section>
                                    )}
                                </div>

                                <div class="col-md-6">
                                    {isEditMode ? (
                                        <section className="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="date_of_birth"
                                                placeholder="First Name"
                                                name="date_of_birth"
                                                onChange={(e) => onChange(e)}
                                                value={date_of_birth}
                                                required
                                            />
                                            <label for="date_of_birth">
                                                Date of Birth{" "}
                                                <small className="text-primary fw-bold">
                                                    (YYYY-MM-DD)
                                                </small>
                                            </label>
                                        </section>
                                    ) : (
                                        <section>
                                            <strong>Date of Birth: </strong>
                                            <p>
                                                {date_of_birth
                                                    ? date_of_birth
                                                    : "--"}
                                            </p>
                                        </section>
                                    )}
                                </div>

                                <section className="col-12 mt-5">
                                    <h5 className="text-center">
                                        Contact Address
                                    </h5>
                                </section>
                                <div class="col-12">
                                    {isEditMode ? (
                                        <section className="form-floating">
                                            <textarea
                                                class="form-control"
                                                placeholder="Leave a comment here"
                                                id="floatingTextarea"
                                                onChange={(e) => onChange(e)}
                                                name="home_address"
                                                value={home_address}
                                                style={{
                                                    height: "150px",
                                                }}
                                            ></textarea>
                                            <label for="floatingTextarea">
                                                Home Address
                                            </label>
                                        </section>
                                    ) : (
                                        <section>
                                            <strong>Home Address: </strong>
                                            <p>
                                                {home_address
                                                    ? home_address
                                                    : "--"}
                                            </p>
                                        </section>
                                    )}
                                </div>
                                <div class="col-md-6">
                                    {isEditMode ? (
                                        <section className="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="local_govt"
                                                placeholder="First Name"
                                                name="local_govt"
                                                onChange={(e) => onChange(e)}
                                                value={local_govt}
                                                required
                                            />
                                            <label for="local_govt">
                                                City/Town
                                            </label>
                                        </section>
                                    ) : (
                                        <section>
                                            <strong>City/Town: </strong>
                                            <p>
                                                {local_govt ? local_govt : "--"}
                                            </p>
                                        </section>
                                    )}
                                </div>

                                <div class="col-md-6">
                                    {isEditMode ? (
                                        <section className="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="state_of_origin"
                                                placeholder="First Name"
                                                name="state_of_origin"
                                                onChange={(e) => onChange(e)}
                                                value={state_of_origin}
                                                required
                                            />
                                            <label for="state_of_origin">
                                                State of Origin
                                            </label>
                                        </section>
                                    ) : (
                                        <section>
                                            <strong>State of Origin: </strong>
                                            <p>
                                                {state_of_origin
                                                    ? state_of_origin
                                                    : "--"}
                                            </p>
                                        </section>
                                    )}
                                </div>
                                <div class="col-md-6">
                                    {isEditMode ? (
                                        <section className="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="nationality"
                                                placeholder="First Name"
                                                name="nationality"
                                                onChange={(e) => onChange(e)}
                                                value={nationality}
                                                required
                                            />
                                            <label for="nationality">
                                                Nationality
                                            </label>
                                        </section>
                                    ) : (
                                        <section>
                                            <strong>Nationality: </strong>
                                            <p>
                                                {nationality
                                                    ? nationality
                                                    : "--"}
                                            </p>
                                        </section>
                                    )}
                                </div>
                            </section>

                            {isEditMode && (
                                <div className="mt-5 d-flex justify-content-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary me-4"
                                    >
                                        Update Profile
                                    </button>

                                    <span
                                        className="btn btn-outline-danger"
                                        onClick={handleFormReset}
                                    >
                                        Cancel Changes
                                    </span>
                                </div>
                            )}
                        </form>
                    </section>
                </div>
            </section>
        </section>
    );
};

export default ProfileInformation;
