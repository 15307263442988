import TextTruncate from "react-text-truncate";

const pic = {
    url: "https://img.freepik.com/premium-photo/imagine-smiling-woman-wearing-headset-working-as-customer-service-representative-generate-ai_905417-5893.jpg?w=1060",
};

const BlogCard = () => {
    return (
        <section className="col">
            <section className="card mx-auto" style={{ maxWidth: "379px" }}>
                <div className="ratio ratio-16x9">
                    <img src={pic.url} className="card-img-top mb-4" alt="" />
                </div>
                <section className="card-body">
                    <small
                        className="fw-bold text-primary me-3"
                        style={{ fontSize: "12px" }}
                    >
                        <i class="fa-sharp fa-solid fa-calendar-days me-1"></i>
                        23rd September, 2024
                    </small>
                    <TextTruncate
                        line={3}
                        element="h5"
                        truncateText="…"
                        text="Blog Long and Long Title"
                        className="card-title"
                    />

                    <TextTruncate
                        line={3}
                        element="p"
                        truncateText="…"
                        text="This is a very short snippet for the blog"
                        className="card-text"
                    />
                </section>
                <div class="card-footer text-primary fw-bold course-card-footer">
                    <section>
                        <span>
                            <i class="fa-solid fa-thumbs-up"></i>{" "}
                            <small className="me-2">10</small>
                        </span>

                        <span>
                            <i class="fa-solid fa-comment"></i>{" "}
                            <small className="me-2">4.8k</small>
                        </span>

                        <span>
                            <i class="fa-solid fa-tag"></i>{" "}
                            <small className="me-2">Category</small>
                        </span>
                    </section>
                </div>
            </section>
        </section>
    );
};

export default BlogCard;
