import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PageTitle from "../pages/pageTitle";
import axios from "axios";
import ContactForm from "../cards/contactForm";
import MailSubscription from "../cards/emailSub";
import { PageLoader } from "../cards/utilities/loader";
import Faq from "../cards/faq";
import CourseReg from "../cards/courseReg";
import TeastimonialGroup from "../groups/testimonialGroup";
import parse from "html-react-parser";
import { Helmet } from "react-helmet-async";

const CourseDetail = () => {
    const { slug } = useParams();
    const [detail, setDetails] = useState([]);
    const dataCheck = !detail || detail.length === 0;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/course/bk-course/` + slug
                );
                setDetails(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                // Set loading to false once the API call is complete (whether it succeeded or failed)
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    const presenceCheck = (presence) => {
        const check =
            !dataCheck &&
            detail.presence.some((obj) =>
                Object.values(obj).includes(presence)
            );
        if (check && presence === "Online") {
            if (detail.payment_type === "Paid") {
                return `Online Cost: N${detail.online_price}`;
            } else {
                return `Onsite Cost: ${detail.payment_type}`;
            }
        } else if (check && presence === "Onsite") {
            if (detail.payment_type === "Paid") {
                return `Onsite Cost: N${detail.onsite_price}`;
            } else {
                return `Onsite Cost: ${detail.payment_type}`;
            }
        } else {
            return "";
        }
    };
    const courseRequirements = [
        {
            id: 1,
            faq_question: "Why you should take this Course?",
            faq_answer: !dataCheck ? detail.why_choose_course : null,
        },
        {
            id: 2,
            faq_question: "What you will learn",
            faq_answer: !dataCheck ? detail.outline : null,
        },
        {
            id: 3,
            faq_question: "Requirements",
            faq_answer: !dataCheck ? detail.requirement : null,
        },
        {
            id: 4,
            faq_question: "Cost and Duration",
            faq_answer: `<p>${
                !dataCheck ? `Duration: ${detail.duration}` : ""
            }</p> <p>${`${presenceCheck("Online")}`}</p> <p>${`${presenceCheck(
                "Onsite"
            )}`}</p>`,
        },
    ];

    return (
        <>
            <Helmet>
                <title>{`${detail.title} - Codehub Technologies`}</title>
            </Helmet>
            <PageTitle title={detail.title} />

            <section className="container pt-10">
                <section className="row">
                    <section className="col-lg-8">
                        {parse(`${detail.description}`)}

                        <Faq
                            faqList={courseRequirements}
                            title="Course Information"
                        />
                    </section>

                    <section className="col-lg-4">
                        <section className="sticky-top">
                            <CourseReg
                                info={!dataCheck && detail}
                                is_track={false}
                            />
                        </section>
                    </section>
                </section>
            </section>

            <TeastimonialGroup
                testimonialList={!dataCheck && detail.course_review}
            />

            <MailSubscription />

            <ContactForm />

            {loading && <PageLoader />}
        </>
    );
};

export default CourseDetail;
