import PageTitle from "./pageTitle";
import { useContext } from "react";
import { CompanyInfoContext } from "../../App";
import parse from "html-react-parser";
import { Helmet } from "react-helmet-async";

const Privacy = () => {
    const companyInfo = useContext(CompanyInfoContext);

    return (
        <section>
            <Helmet>
                <title>Our Privacy Statement - Codehub Technologies</title>
            </Helmet>

            <PageTitle title="Our Privacy Statement" />

            <section className="container py-8">
                <section className="row">
                    <section className="col-lg-8 mx-auto">
                        {parse(`${companyInfo.privacy_policy}`)}
                    </section>
                </section>
            </section>
        </section>
    );
};

export default Privacy;
