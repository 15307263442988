import React, { Component } from "react";
import ServiceGroup from "../groups/serviceCardGroup";
import ClientGroup from "../groups/clientCardGroup";
import RecentJobGroup from "../groups/recentJobGroup";
import TestimonialGroup from "../groups/testimonialGroup";
import HeroSection from "../cards/hero";
import MailSubscription from "../cards/emailSub";
import ContactForm from "../cards/contactForm";
import WorkProcessGroup from "../groups/workProcessGroup";
import TeamGroup from "../groups/teamGroup";
import { useContext } from "react";
import { TestimonialContext, ServiceContext } from "../../App";
import { Helmet } from "react-helmet-async";

const Home = () => {
    const testimonials = useContext(TestimonialContext);
    const services = useContext(ServiceContext);
    return (
        <>
            <Helmet>
                <title>Codehub Technologies</title>
            </Helmet>
            <HeroSection heroList={services} />
            <ClientGroup />
            <ServiceGroup type={true} />
            <RecentJobGroup />
            <WorkProcessGroup />
            <TeamGroup />
            <TestimonialGroup testimonialList={testimonials} />
            <MailSubscription />
            <ContactForm />
        </>
    );
};

export default Home;
