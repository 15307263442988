const CourseProgress = () => {
    return (
        <section className="card p-3 text-center" style={{ maxWidth: "200px" }}>
            <section className="d-flex justify-content-center">
                <section className="my-progress-bar mb-3">
                    <h4 className="percent">50%</h4>
                </section>
            </section>

            <section>
                <h6>Course Title</h6>
                <p>6 Modules</p>
            </section>
        </section>
    );
};

export default CourseProgress;
