import HeaderBar from "./components/headerBar";
import PaymentHistory from "./Payments/PaymentHistory";
import PaymentCart from "./Payments/paymentCart";
import Nav from "react-bootstrap/Nav";
import { useState } from "react";

const StudentPayments = () => {
    const [activeTab, setActiveTab] = useState("#payment-cart");

    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    return (
        <section>
            <HeaderBar pageHeader="Payments">
                <Nav
                    variant="pills"
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    className=""
                >
                    <Nav.Item className="border border-2 border-primary me-2">
                        <Nav.Link eventKey="#payment-cart">Cart</Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="border border-2 border-primary">
                        <Nav.Link eventKey="#payment-history">
                            Payment History
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </HeaderBar>

            <section className="container">
                {activeTab === "#payment-cart" && <PaymentCart />}

                {activeTab === "#payment-history" && <PaymentHistory />}
            </section>
        </section>
    );
};

export default StudentPayments;
