import { CompanyInfoContext } from "../../../App";
import { useContext, useState, useEffect } from "react";
import ToastContainer from "react-bootstrap/ToastContainer";
import ToastMsg from "../../cards/utilities/toastSuccess";
import { extractErrorMessages } from "./ExtractErrorMsg";

const pic = {
    url: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwallpaperaccess.com%2Ffull%2F2221274.jpg&f=1&nofb=1&ipt=f0e2873b75a0926044a35fa7eed917b2890c5ff62d0b1da4cd4f9f1db5e54ee3&ipo=images",
};

//npm install --save redux @redux-devtools/extension react-redux redux-thunk @reduxjs/toolkit

// Utility function to extract error messages

export const myStyle = {
    backgroundImage: `linear-gradient(0deg, rgba(0, 61, 43, 0.5), rgba(0, 61, 43, 0.5)), url(${pic.url})`,
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundAttachment: "fixed",
};

const AuthUITemplate = ({ children, errorMsg, title }) => {
    const companyInfo = useContext(CompanyInfoContext);
    // Toast Messages
    const [toasts, setToasts] = useState([]);
    const [show, setShow] = useState(false);

    const resetError = !!errorMsg;
    useEffect(() => {
        const hasValue = () => {
            setToasts([]);
            return resetError;
        };
        hasValue() ? setShow(true) : setShow(false);
    }, [resetError]);

    useEffect(() => {
        if (errorMsg) {
            const extractedMessages = extractErrorMessages(errorMsg);
            const newToasts = extractedMessages.map((message) => ({
                show: true,
                bg: "danger",
                Toastheader: "Error",
                message: message,
            }));
            setToasts((prevToasts) => [...prevToasts, ...newToasts]);
        }
    }, [errorMsg]);

    const handleCloseToast = (index) => {
        setToasts((prevToasts) =>
            prevToasts.map((toast, i) =>
                i === index ? { ...toast, show: false } : toast
            )
        );
    };

    return (
        <section style={myStyle}>
            <section
                className="container min-vh-100 py-10 d-flex justify-content-center align-items-center"
                style={{ maxWidth: "515px" }}
            >
                <section className="container card auth-card py-8 px-4">
                    <header className="mx-auto mb-3 text-center">
                        <h4>{title}</h4>
                    </header>

                    {children}
                </section>
            </section>

            {show && (
                <ToastContainer position="top-end" style={{ zIndex: 999999 }}>
                    {toasts.map((toast, index) => (
                        <ToastMsg
                            key={index}
                            onClose={() => handleCloseToast(index)}
                            message={toast.message}
                            Toastheader={toast.Toastheader}
                            show={toast.show}
                            bg={toast.bg}
                        />
                    ))}
                </ToastContainer>
            )}
        </section>
    );
};

export default AuthUITemplate;
