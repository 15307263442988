import { useState, useEffect } from "react";
import LoaderIcon from "../../cards/utilities/loader";
import { Link } from "react-router-dom";
import AuthUITemplate from "./AuthUI";
import ToastSuccess from "../../cards/utilities/toastSuccess";
import useSignup from "../../../hooks/useSignup";
import HideShowPassword from "./hideShowPassword";

const apiURL = process.env.REACT_APP_API_URL;

const SignUp = () => {
    // Initial Data
    const initialFormData = {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        password: "",
        re_password: "",
    };

    const [hideNewPassword, setHideCNewPassword] = useState(false);
    const [hideReNewPassword, setHideReNewPassword] = useState(false);

    const { error, formData, status, loading, onChange, onSubmit } = useSignup(
        initialFormData,
        apiURL
    );

    //Toast
    const [showToast, setToastShow] = useState(false);
    const handleToastClose = () => {
        setToastShow(false);
    };
    const handleToastShow = () => setToastShow(true);

    useEffect(() => {
        if (status === 201) {
            handleToastShow();
        }
    }, [status]);

    return (
        <AuthUITemplate title="Create Account" errorMsg={error}>
            <form className="row g-3" onSubmit={(e) => onSubmit(e)}>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type="text"
                            class="form-control"
                            id="first_name"
                            placeholder="First Name"
                            name="first_name"
                            onChange={(e) => onChange(e)}
                            value={formData.first_name}
                            required
                        />
                        <label for="first_name">First Name</label>
                    </section>
                </section>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type="text"
                            class="form-control"
                            id="last_name"
                            placeholder="Last Name"
                            name="last_name"
                            onChange={(e) => onChange(e)}
                            value={formData.last_name}
                            required
                        />
                        <label for="last_name">Last Name</label>
                    </section>
                </section>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder="Full Name"
                            name="email"
                            onChange={(e) => onChange(e)}
                            value={formData.email}
                            required
                        />
                        <label for="email">Email address</label>
                    </section>
                </section>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type="text"
                            class="form-control"
                            id="phone_number"
                            placeholder="Full Name"
                            name="phone_number"
                            onChange={(e) => onChange(e)}
                            value={formData.phone_number}
                            required
                        />
                        <label for="phone_number">Phone Number</label>
                    </section>
                </section>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type={hideNewPassword ? "text" : "password"}
                            class="form-control position-relative pe-7"
                            id="password"
                            placeholder="Password"
                            name="password"
                            onChange={(e) => onChange(e)}
                            value={formData.password}
                            required
                        />
                        <HideShowPassword
                            hidePassword={hideNewPassword}
                            setHidePassword={setHideCNewPassword}
                        />
                        <label for="password">Password</label>
                    </section>
                </section>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type={hideReNewPassword ? "text" : "password"}
                            class="form-control position-relative pe-7"
                            id="re_password"
                            placeholder="Confirm Password"
                            name="re_password"
                            onChange={(e) => onChange(e)}
                            value={formData.re_password}
                            required
                        />
                        <HideShowPassword
                            hidePassword={hideReNewPassword}
                            setHidePassword={setHideReNewPassword}
                        />
                        <label for="re_password">Confirm Password</label>
                    </section>
                </section>

                <section className="col-12 d-grid">
                    <button
                        type="submit"
                        className={
                            loading
                                ? "btn btn-primary disabled"
                                : "btn btn-primary"
                        }
                    >
                        {loading && <LoaderIcon />}
                        Create Account
                    </button>
                </section>
            </form>
            <section class="mt-3">
                <p class="text-center">
                    Already have an account?{" "}
                    <Link to="/login" class="fw-bold text-decoration-none">
                        Log In
                    </Link>
                </p>
            </section>

            <ToastSuccess
                show={showToast}
                onClose={handleToastClose}
                Toastheader="Account Created"
                message="Confirmation email has been sent to your email account."
            />
        </AuthUITemplate>
    );
};

export default SignUp;
