import CourseCard from "../cards/courseCard";
import HeroSection from "../cards/hero";
import TeastimonialGroup from "../groups/testimonialGroup";
import MailSubscription from "../cards/emailSub";
import ContactForm from "../cards/contactForm";
import { useContext } from "react";
import {
    TestimonialContext,
    CourseTrackContext,
    CourseContext,
    CourseHeroContext,
} from "../../App";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Course = () => {
    const testimonials = useContext(TestimonialContext);
    const tracks = useContext(CourseTrackContext);
    const courses = useContext(CourseContext);
    const hero = useContext(CourseHeroContext);

    return (
        <>
            <Helmet>
                <title>Courses - Codehub Technologies</title>
            </Helmet>

            {Object.keys(hero).length > 0 && <HeroSection heroList={hero} />}

            {Object.keys(tracks).length > 0 && (
                <section className="container pt-10">
                    <header className="text-center mb-4">
                        <h2>Our Course Tracks</h2>
                        <h6>See Our Exceptional Course Tracks</h6>
                    </header>
                    <section className="row row-cols-1 row-cols-lg-3 g-6 justify-content-center">
                        {tracks.map((item) => (
                            <Link
                                className="text-decoration-none"
                                to={"/track/" + item.slug}
                            >
                                <CourseCard
                                    title={item.title}
                                    outline_snippet={item.outline_snippet}
                                    level={item.level}
                                    duration={item.duration}
                                    payment={item.payment_type}
                                    image={item.track_img_url}
                                    id={item.id}
                                />
                            </Link>
                        ))}
                    </section>
                </section>
            )}

            {Object.keys(courses).length > 0 && (
                <section className="container py-10">
                    <header className="text-center mb-4">
                        <h2>Our Courses</h2>
                        <h6>Discover Our Courses</h6>
                    </header>

                    <section className="row row-cols-1 row-cols-lg-3 g-6 justify-content-center">
                        {courses.map((item) => (
                            <Link
                                className="text-decoration-none"
                                to={"/courses/" + item.slug}
                            >
                                <CourseCard
                                    title={item.title}
                                    outline_snippet={item.outline_snippet}
                                    level={item.level}
                                    duration={item.duration}
                                    payment={item.payment_type}
                                    image={item.track_img_url}
                                    id={item.id}
                                />
                            </Link>
                        ))}
                    </section>
                </section>
            )}

            <TeastimonialGroup testimonialList={testimonials} />

            <MailSubscription />

            <ContactForm />
        </>
    );
};

export default Course;
