import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import Faq from "../../../cards/faq";
import CourseReg from "../../../cards/courseReg";
import TeastimonialGroup from "../../../groups/testimonialGroup";
import CourseCard from "../../../cards/courseCard";

const StudentTrackDetail = () => {
    const { slug } = useParams();
    const [detail, setDetails] = useState([]);
    const dataCheck = !detail || detail.length === 0;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/course/bk-track/` + slug
                );
                setDetails(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                // Set loading to false once the API call is complete (whether it succeeded or failed)
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    const presenceCheck = (presence) => {
        const check =
            !dataCheck &&
            detail.presence.some((obj) =>
                Object.values(obj).includes(presence)
            );
        if (check && presence === "Online") {
            if (detail.payment_type === "Paid") {
                return `Online Cost: N${detail.online_price}`;
            } else {
                return `Onsite Cost: ${detail.payment_type}`;
            }
        } else if (check && presence === "Onsite") {
            if (detail.payment_type === "Paid") {
                return `Onsite Cost: N${detail.onsite_price}`;
            } else {
                return `Onsite Cost: ${detail.payment_type}`;
            }
        } else {
            return "";
        }
    };

    const courseRequirements = [
        {
            id: 1,
            faq_question: "Why you should take this Track?",
            faq_answer: !dataCheck ? detail.why_choose_track : null,
        },
        {
            id: 2,
            faq_question: "What you will learn",
            faq_answer: !dataCheck ? detail.outline : null,
        },
        {
            id: 3,
            faq_question: "Requirements",
            faq_answer: !dataCheck ? detail.requirement : null,
        },
        {
            id: 4,
            faq_question: "Cost and Duration",
            faq_answer: `<p>${
                !dataCheck ? `Duration: ${detail.duration}` : ""
            }</p> <p>${`${presenceCheck("Online")}`}</p> <p>${`${presenceCheck(
                "Onsite"
            )}`}</p>`,
        },
    ];

    return (
        <>
            <section className="container">
                <h2 className="text-center mb-2">{detail.title}</h2>
                <section className="row">
                    <section className="col-lg-8">
                        {parse(`${detail.description}`)}

                        <Faq
                            faqList={courseRequirements}
                            title="Track Information"
                        />
                    </section>

                    <section className="col-lg-4">
                        <section className="sticky-top">
                            <CourseReg info={detail} is_track={true} />
                        </section>
                    </section>
                </section>

                {dataCheck ? null : (
                    <>
                        {" "}
                        {Object.keys(detail.course_in_track).length ===
                        0 ? null : (
                            <section>
                                <header className="text-center mb-4">
                                    <h2>Track Courses</h2>
                                    <h6>Courses available on this Track</h6>
                                </header>

                                <section className="row row-cols-1 row-cols-lg-3 g-6 justify-content-center">
                                    {detail.course_in_track.map((item) => (
                                        <CourseCard
                                            title={item.title}
                                            outline_snippet={
                                                item.outline_snippet
                                            }
                                            level={item.level}
                                            duration={item.duration}
                                            image={item.track_img_url}
                                            id={item.id}
                                        />
                                    ))}
                                </section>
                            </section>
                        )}
                    </>
                )}
            </section>

            <TeastimonialGroup
                testimonialList={!dataCheck && detail.track_review}
            />
        </>
    );
};

export default StudentTrackDetail;
