const HideShowPassword = ({ hidePassword, setHidePassword }) => {
    const handleClick = () => {
        setHidePassword(!hidePassword);
    };
    return (
        <span
            className="position-absolute"
            style={{
                bottom: "16px",
                right: "8px",
                // width: "40px",
                // height: "40px",
                cursor: "pointer",
            }}
            onClick={handleClick}
        >
            {hidePassword ? "Hide" : "Show"}
        </span>
    );
};

export default HideShowPassword;
