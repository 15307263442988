import TopBar from "./topBar";
import { useState } from "react";
import ToastSuccess from "../../cards/utilities/toastSuccess";
import StudentDashboardFooter from "./components/footer";

const StudentDashboardLayout = () => {
    //Toast
    const [showToast, setToastShow] = useState(false);
    const handleToastClose = () => {
        setToastShow(false);
    };
    const handleToastShow = () => setToastShow(true);

    return (
        <section>
            <TopBar />
            <ToastSuccess
                show={showToast}
                onClose={handleToastClose}
                Toastheader="Session Expired"
                message="Your sessions have expired. Kindly login again"
            />
        </section>
    );
};

export default StudentDashboardLayout;
