import LoaderIcon from "../../../cards/utilities/loader";
import useChangeAuthenticatedUserPassword from "../../../../hooks/useChangePassword";
import { useEffect, useState } from "react";
import HideShowPassword from "../../authentications/hideShowPassword";

const apiURL = process.env.REACT_APP_API_URL;

const ChangePassword = () => {
    // Initial Data
    const initialFormData = {
        current_password: "",
        new_password: "",
        re_new_password: "",
    };

    const [hideCurrentPassword, setHideCurrentPassword] = useState(false);
    const [hideNewPassword, setHideCNewPassword] = useState(false);
    const [hideReNewPassword, setHideReNewPassword] = useState(false);

    const { error, formData, loading, onChange, onSubmit, status } =
        useChangeAuthenticatedUserPassword(initialFormData, apiURL);

    useEffect(() => {
        if (error) {
            alert(error);
        }
    }, [error]);
    return (
        <section className="container">
            <form className="row g-3" onSubmit={(e) => onSubmit(e)}>
                <div class="card no-card-style py-8 px-4 px-lg-8 col-lg-10 mx-auto">
                    <section className="row g-3">
                        <header className="text-center">
                            <h5>Change your Password</h5>
                            <p>
                                Confirm your password and enter a new one below.
                            </p>
                        </header>
                        <section className="col-md-12">
                            <section className="form-floating">
                                <input
                                    type={
                                        hideCurrentPassword
                                            ? "text"
                                            : "password"
                                    }
                                    class="form-control position-relative pe-7"
                                    id="current_password"
                                    placeholder="Password"
                                    name="current_password"
                                    onChange={(e) => onChange(e)}
                                    value={formData.current_password}
                                    required
                                />
                                <HideShowPassword
                                    hidePassword={hideCurrentPassword}
                                    setHidePassword={setHideCurrentPassword}
                                />
                                <label for="current_password">
                                    Current Password
                                </label>
                            </section>
                        </section>
                        <section className="col-md-12">
                            <section className="form-floating">
                                <input
                                    type={hideNewPassword ? "text" : "password"}
                                    class="form-control position-relative pe-7"
                                    id="new_password"
                                    placeholder="Password"
                                    name="new_password"
                                    onChange={(e) => onChange(e)}
                                    value={formData.new_password}
                                    required
                                />
                                <HideShowPassword
                                    hidePassword={hideNewPassword}
                                    setHidePassword={setHideCNewPassword}
                                />
                                <label for="new_password">New Password</label>
                            </section>
                        </section>
                        <section className="col-md-12">
                            <section className="form-floating">
                                <input
                                    type={
                                        hideReNewPassword ? "text" : "password"
                                    }
                                    class="form-control position-relative pe-7"
                                    id="re_new_password"
                                    placeholder="Confirm Password"
                                    name="re_new_password"
                                    onChange={(e) => onChange(e)}
                                    value={formData.re_new_password}
                                    required
                                />
                                <HideShowPassword
                                    hidePassword={hideReNewPassword}
                                    setHidePassword={setHideReNewPassword}
                                />
                                <label for="re_new_password">
                                    Confirm New Password
                                </label>
                            </section>
                        </section>

                        <section className="col-12 d-grid mt-5">
                            <button
                                type="submit"
                                className={
                                    loading
                                        ? "btn btn-primary disabled"
                                        : "btn btn-primary"
                                }
                            >
                                {loading ? <LoaderIcon /> : null}
                                Change Password
                            </button>
                        </section>
                    </section>
                </div>
            </form>
        </section>
    );
};

export default ChangePassword;
