import CourseProgress from "./components/courseProgress";
import HeaderBar from "./components/headerBar";

const StudentDashboard = () => {
    return (
        <section className="">
            <HeaderBar pageHeader="Dashboard" />

            <main className="container">
                <section className="mb-4">
                    <h4>Welcome Samuel!</h4>
                </section>

                <section className="mb-4">
                    <header className="mb-4">
                        <h4>Current Track Progress</h4>
                    </header>

                    <CourseProgress />
                </section>

                <section className="mb-4">
                    <header className="mb-4">
                        <h4>Current Course Progress</h4>
                    </header>

                    <CourseProgress />
                </section>
            </main>
        </section>
    );
};

export default StudentDashboard;
