import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthenticated, load_user, clearErrors } from "../actions/auth";

const useAuthicatedUser = (apiURL, location) => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const error = useSelector((state) => state.auth.error);
    const currentUser = useSelector((state) => state.auth.user);

    const handleAuthCheck = useCallback(
        async (e) => {
            // e.preventDefault();

            try {
                const result = await dispatch(checkAuthenticated(apiURL));
                if (result) {
                    await dispatch(load_user(apiURL));
                }
            } catch (error) {
                console.error("Authentication check failed", error);
            }
        },
        [dispatch, apiURL]
    );

    useEffect(() => {
        handleAuthCheck(); // Call handleAuthCheck when the component mounts
    }, [location]);

    return {
        error,
        isAuthenticated,
        currentUser,
        clearErrors,
    };
};

export default useAuthicatedUser;
