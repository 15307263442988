import { useState, useEffect, cloneElement } from "react";
import ReactPaginate from "react-paginate";

function usePagination({ itemsPerPage, data, selectedCategory, children }) {
    const [itemOffset, setItemOffset] = useState(0);
    const items = Object.values(data);

    useEffect(() => {
        if (selectedCategory) {
            setItemOffset(0);
        }
    }, [selectedCategory]);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    const clonedChildren = cloneElement(children, { currentItems });

    const paginationUI = (
        <>
            <section className="container">
                {clonedChildren}
                {pageCount > 1 && (
                    <ReactPaginate
                        previousLabel={`Prev`}
                        nextLabel={"Next"}
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        marginPagesDisplayed={3}
                        containerClassName={
                            "pagination justify-content-center mt-6"
                        }
                        pageClassName={"page-item me-2"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item me-5"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item ms-4"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item me-1"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                )}
            </section>
        </>
    );

    return { paginationUI };
}

export default usePagination;
