import Toast from "react-bootstrap/Toast";

const ToastMsg = ({ onClose, message, Toastheader, show, bg }) => {
    return (
        <Toast onClose={onClose} show={show} delay={8000} autohide bg={bg}>
            <Toast.Header>
                <strong className="me-auto">{Toastheader}</strong>
            </Toast.Header>
            <Toast.Body className={bg !== "white" && "text-white"}>
                {message}
            </Toast.Body>
        </Toast>
    );
};

export default ToastMsg;
