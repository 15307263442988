import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { CompanyInfoContext } from "../../App";

const PageTitle = ({ title }) => {
    const companyInfo = useContext(CompanyInfoContext);

    const myStyle = {
        backgroundImage: `url(${companyInfo.get_page_header_image})`,
        height: "227px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        boxShadow: "inset 0 0 0 100vh rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x); // Split and filter out empty segments

    return (
        <>
            <section style={myStyle}>
                <h1 className="container text-white text-center">
                    {title ? title : "Page Title"}
                </h1>
            </section>

            {/* <div className="shadow-sm">
                <nav aria-label="breadcrumb" className="container">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <Link
                                to="/"
                                className="nav-link active"
                                aria-current="page"
                            >
                                <i class="fa-solid fa-house"></i>
                            </Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            {title}
                        </li>
                    </ol>
                </nav>
            </div> */}
            <div className="shadow-sm">
                <nav aria-label="breadcrumb" className="container">
                    <ol className="breadcrumb">
                        {/* Home icon leading to the root */}
                        <li className="breadcrumb-item">
                            <Link
                                to="/"
                                className="nav-link active"
                                aria-current="page"
                            >
                                <i className="fa-solid fa-house"></i>
                            </Link>
                        </li>

                        {/* Dynamically generated breadcrumbs */}
                        {pathnames.map((value, index) => {
                            const to = `/${pathnames
                                .slice(0, index + 1)
                                .join("/")}`;
                            const isLast = index === pathnames.length - 1;

                            // Function to convert string to Title Case
                            const toTitleCase = (str) => {
                                return str
                                    .split(" ")
                                    .map(
                                        (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                    )
                                    .join(" ");
                            };

                            // Replace dashes with spaces and convert to Title Case
                            const formattedValue = toTitleCase(
                                value.replace("-", " ")
                            );

                            return isLast ? (
                                // If it's the last breadcrumb, don't make it a link
                                <li
                                    key={to}
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                >
                                    {formattedValue}
                                </li>
                            ) : (
                                // If it's not the last breadcrumb, render a link
                                <li key={to} className="breadcrumb-item">
                                    <Link
                                        to={to}
                                        className="nav-link active"
                                        style={{ display: "inline" }}
                                    >
                                        {formattedValue}
                                    </Link>
                                </li>
                            );
                        })}
                    </ol>
                </nav>
            </div>
        </>
    );
};

export default PageTitle;
