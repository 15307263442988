import { useState, useContext, useEffect, createContext } from "react";
import {
    Outlet,
    Link,
    NavLink,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { CompanyInfoContext } from "../../../App";
import StudentDashboardFooter from "./components/footer";
// import Socials from "../../cards/socials";
import useAuthicatedUser from "../../../hooks/useLoadUser";
import useLogout from "../../../hooks/useLogout";

export const userInfo = createContext(null);

const pic = {
    url: "http://www.highreshdwallpapers.com/wp-content/uploads/2014/01/Athletic-Running-HD-Image.jpg",
};
const apiURL = process.env.REACT_APP_API_URL;

const TopBar = () => {
    const companyInfo = useContext(CompanyInfoContext);
    const location = useLocation();

    useEffect(() => {
        localStorage.setItem("from", location.pathname);
    }, [location.pathname]);

    const dataCheck =
        !companyInfo.company_social || companyInfo.company_social.length === 0;

    // Sticky Navbar
    const [fix, seFix] = useState(false);

    function handleFixNavBar() {
        if (window.scrollY >= 10) {
            seFix(true);
        } else {
            seFix(false);
        }
    }

    window.addEventListener("scroll", handleFixNavBar);

    // Offcanvas
    const [showOffcanvas, setOffcanvasShow] = useState(false);
    const handleOffcanvasClose = () => setOffcanvasShow(false);
    const handleOffcanvasShow = () => setOffcanvasShow(true);

    const [toggleSideMenu, setToggleSideMenu] = useState(() => {
        // Check if the window width is less than 1024px (small and medium devices)
        return window.matchMedia("(min-width: 1024px)").matches;
    });

    // Toggle Menu
    function handleToggleSideMenu() {
        setToggleSideMenu(!toggleSideMenu);
    }

    useEffect(() => {
        const handleResize = () => {
            setToggleSideMenu(window.matchMedia("(min-width: 1024px)").matches);
        };

        // Attach the resize event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Check Auth and Load user
    const { isAuthenticated, currentUser, error } = useAuthicatedUser(
        apiURL,
        location
    );

    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login", { replace: true });
        }

        if (!isAuthenticated && location.pathname !== "/login") {
            navigate(`/login?ReturnUrl=${location.pathname}`, {
                replace: true,
            });
        }
    }, [isAuthenticated, navigate]);

    // Log user out
    const handleLogout = useLogout();
    const logout_user = () => {
        handleLogout();
        navigate("/login", { replace: true });
    };

    return (
        <userInfo.Provider value={currentUser}>
            <section style={{ backgroundColor: "rgb(246, 245, 247)" }}>
                <section className="content-area">
                    {toggleSideMenu && (
                        <section className="side-navbar py-4 px-2">
                            <section className="position-relative">
                                <section
                                    onClick={handleToggleSideMenu}
                                    className="d-block d-sm-block d-md-block d-lg-none position-absolute top-0 end-0 px-4"
                                >
                                    <i class="fa-solid fa-x text-primary fs-4"></i>
                                </section>
                                <section className="text-center mb-5">
                                    <img
                                        src={pic.url}
                                        className="rounded-circle mb-2"
                                        width="80"
                                        height="80"
                                        alt="..."
                                    />
                                    <h6>{`${currentUser?.first_name} ${currentUser?.last_name}`}</h6>
                                    <small>Student</small>
                                </section>

                                <section>
                                    <div
                                        className="navbar-collapse justify-content-center custom-bar"
                                        id="navbarNav"
                                    >
                                        <ul className="nav nav-pills flex-column">
                                            <li className="nav-item">
                                                <NavLink
                                                    to="/student/dashboard"
                                                    className="nav-link fw-light"
                                                    aria-current="page"
                                                    end
                                                    // onClick={handleToggleSideMenu}
                                                >
                                                    <i class="fa-solid fa-house me-2"></i>
                                                    Dashboard
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/student/dashboard/courses"
                                                    className="nav-link fw-light"
                                                    end
                                                >
                                                    <i class="fa-solid fa-graduation-cap me-2"></i>
                                                    Courses
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/student/dashboard/my-courses"
                                                    className="nav-link fw-light"
                                                    end
                                                >
                                                    <i class="fa-solid fa-bookmark me-2"></i>
                                                    My Learning
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/student/dashboard/payments"
                                                    className="nav-link fw-light"
                                                    end
                                                >
                                                    <i class="fa-solid fa-cart-shopping me-2"></i>
                                                    Payments
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/student/dashboard/profile"
                                                    className="nav-link fw-light"
                                                    end
                                                >
                                                    <i class="fa-solid fa-user me-2"></i>
                                                    Profile
                                                </NavLink>
                                            </li>

                                            <li
                                                className="nav-item"
                                                onClick={logout_user}
                                            >
                                                <a
                                                    className="nav-link fw-light"
                                                    href="#"
                                                >
                                                    <i class="fa-solid fa-right-from-bracket me-2"></i>
                                                    Logout
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </section>
                            </section>
                        </section>
                    )}

                    <section
                        className={toggleSideMenu && "my-container active-cont"}
                    >
                        <nav
                            className={
                                fix
                                    ? "navbar topbar-fixed shadow navbar-expand-lg bg-white"
                                    : "navbar navbar-expand-lg bg-white shadow"
                            }
                        >
                            <div className="container-fluid">
                                <section className="navbar-brand d-flex align-items-center">
                                    <section onClick={handleToggleSideMenu}>
                                        <i class="fa-solid fa-bars text-primary fs-4"></i>
                                    </section>

                                    {/* <img
                                    src={companyInfo.get_logo}
                                    alt={`${companyInfo.company_name}'s Logo`}
                                    width="50"
                                    height="50"
                                    className="d-inline-block align-text-top ms-3"
                                /> */}
                                </section>

                                <section className="d-flex align-items-center ">
                                    <section className="me-4 d-block d-sm-block d-md-block d-lg-none">
                                        <Link
                                            to="/student/dashboard/payments"
                                            className="position-relative"
                                        >
                                            <i class="fa-solid fa-calendar-days fs-5"></i>
                                        </Link>
                                    </section>

                                    <section className="d-block d-sm-block d-md-block d-lg-none">
                                        <span className="me-3 position-relative">
                                            <i className="fs-5 fa-solid fa-bell text-primary">
                                                <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                                                    <span className="visually-hidden">
                                                        unread messages
                                                    </span>
                                                </span>
                                            </i>
                                        </span>
                                    </section>

                                    <section className="btn-group">
                                        <section
                                            className="dropdown-toggle"
                                            id="dropdownMenuButton2"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="true"
                                            aria-expanded="false"
                                        >
                                            <a>
                                                <img
                                                    src={pic.url}
                                                    className="rounded-circle mx-auto"
                                                    width="40"
                                                    height="40"
                                                    alt="..."
                                                />
                                            </a>
                                        </section>

                                        <ul
                                            className="dropdown-menu dropdown-menu-end dropdown-menu-start"
                                            aria-labelledby="dropdownMenuButton2"
                                        >
                                            <li>
                                                <Link
                                                    class="dropdown-item"
                                                    to="/student/dashboard/profile"
                                                >
                                                    Profile
                                                </Link>
                                            </li>
                                            <li>
                                                <a
                                                    class="dropdown-item"
                                                    href="#"
                                                    onClick={logout_user}
                                                >
                                                    Sign Out
                                                </a>
                                            </li>
                                        </ul>
                                    </section>
                                </section>
                            </div>
                        </nav>

                        <section className="min-vh-100">
                            <Outlet />
                        </section>

                        <StudentDashboardFooter />
                    </section>
                </section>
            </section>
        </userInfo.Provider>
    );
};

export default TopBar;
