import TextTruncate from "react-text-truncate";

const DashCourseCard = ({ title, level, duration, payment, image, id }) => {
    return (
        <section className="col" key={id}>
            <section
                className="card mx-auto position-relative"
                style={{ maxWidth: "379px" }}
            >
                <div className="ratio ratio-16x9">
                    <img
                        src={image}
                        className="card-img-top mb-4"
                        alt={title}
                    />
                </div>
                <section className="card-body">
                    <TextTruncate
                        line={3}
                        element="h6"
                        truncateText="…"
                        text={title}
                        className="card-title"
                    />
                </section>
                <div class="card-footer text-primary fw-bold course-card-footer">
                    <section>
                        <span>
                            <i class="fa-solid fa-user"></i>{" "}
                            <small className="me-2">{level}</small>
                        </span>

                        <span>
                            <i class="fa-solid fa-star"></i>{" "}
                            <small className="me-2">4.8 (132k Reviews)</small>
                        </span>
                    </section>
                    <section>
                        <span>
                            <i class="fa-solid fa-graduation-cap"></i>{" "}
                            <small className="me-2">
                                Professional Certificate
                            </small>
                        </span>

                        <span>
                            <i class="fa-regular fa-clock"></i>{" "}
                            <small>{duration}</small>
                        </span>
                    </section>
                </div>
                <div class="position-absolute top-0 end-0">
                    <span class="badge text-bg-danger">{payment}</span>
                </div>
            </section>
        </section>
    );
};

export default DashCourseCard;
