export const extractErrorMessages = (errorMsg) => {
    if (typeof errorMsg === "string") {
        return [errorMsg];
    }

    if (Array.isArray(errorMsg)) {
        return errorMsg;
    }

    if (typeof errorMsg === "object" && errorMsg !== null) {
        const extractedMessages = [];
        Object.keys(errorMsg).forEach((key) => {
            const value = errorMsg[key];
            if (Array.isArray(value)) {
                extractedMessages.push(...value);
            } else if (typeof value === "string") {
                extractedMessages.push(value);
            }
        });
        return extractedMessages;
    }

    return [];
};
