import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import OnsiteCourseRegModal from "./onsiteCourseReg";
import OnlineCourseRegModal from "./onlineCourseReg";

const CourseReg = ({ info, is_track }) => {
    const [activeTab, setActiveTab] = useState("#first");

    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };

    //Onsite Reg Modal
    const [showOnsite, setShowOnsite] = useState(false);
    const handleOnsiteClose = () => {
        setShowOnsite(false);
    };
    const handleOnsiteShow = () => setShowOnsite(true);

    //Online Reg Modal
    const [showOnline, setShowOnline] = useState(false);
    const handleOnlineClose = () => {
        setShowOnline(false);
    };
    const handleOnlineShow = () => setShowOnline(true);

    const presenceCheck = (presence) => {
        if (info.presence) {
            const check = info.presence.some((obj) =>
                Object.values(obj).includes(presence)
            );
            return check;
        }
    };
    return (
        <>
            <Card style={{ maxWidth: "379px" }}>
                <Card.Header>
                    <Nav
                        variant="pills"
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                        className="justify-content-center"
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="#first">
                                Onsite Classes
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="#link">Online Classes</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    {activeTab === "#first" && (
                        <>
                            {presenceCheck("Onsite") ? (
                                <>
                                    <table class="table">
                                        <thead class="table-primary">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    colspan="3"
                                                    class="text-center"
                                                >
                                                    Onsite Class Features
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-check"></i>
                                                </td>
                                                <td>Instructor-led Sessions</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-check"></i>
                                                </td>
                                                <td>
                                                    Networking Opportunities
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-check"></i>
                                                </td>
                                                <td>
                                                    Hands-on Learning Experience
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-check"></i>
                                                </td>
                                                <td>
                                                    Interactive Learning
                                                    Environment
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-check"></i>
                                                </td>
                                                <td>
                                                    Certificate of Completion
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="d-grid">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mb-3"
                                            onClick={handleOnsiteShow}
                                        >
                                            Register for Onsite Classes
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <h4>
                                    Onsite Classes Not available for this{" "}
                                    {is_track ? "Track" : "Course"}
                                </h4>
                            )}
                        </>
                    )}
                    {activeTab === "#link" && (
                        <>
                            {presenceCheck("Online") ? (
                                <>
                                    {" "}
                                    <table class="table">
                                        <thead class="table-primary">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    colspan="3"
                                                    class="text-center"
                                                >
                                                    Online Class Features
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-check"></i>
                                                </td>
                                                <td>Self-paced Learning</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-check"></i>
                                                </td>
                                                <td>
                                                    24/7 Access to Resources
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-check"></i>
                                                </td>
                                                <td>
                                                    Networking Opportunities
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-check"></i>
                                                </td>
                                                <td>
                                                    Hands-on Learning Experience
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fa-solid fa-check"></i>
                                                </td>
                                                <td>
                                                    Certificate of Completion
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="d-grid">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mb-3"
                                            onClick={handleOnlineShow}
                                        >
                                            Register for Online Classes
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <h4>
                                    Online Classes Not available for this{" "}
                                    {is_track ? "Track" : "Course"}
                                </h4>
                            )}
                        </>
                    )}
                </Card.Body>
            </Card>

            <OnsiteCourseRegModal
                show={showOnsite}
                onClose={handleOnsiteClose}
                is_track={is_track}
                regInfo={info}
            />

            <OnlineCourseRegModal
                show={showOnline}
                onClose={handleOnlineClose}
                is_track={is_track}
                regInfo={info}
            />
        </>
    );
};

export default CourseReg;
