import AuthUITemplate from "./AuthUI";
import { useState, useEffect } from "react";
import LoaderIcon from "../../cards/utilities/loader";
import { Link } from "react-router-dom";
import useResetPassword from "../../../hooks/useResetPassword";

const apiURL = process.env.REACT_APP_API_URL;

const ResetPassword = () => {
    const { email, error, handleChange, handleSubmit, loading, status } =
        useResetPassword(apiURL);

    useEffect(() => {
        if (status === 204) {
            alert("Success");
        }
    }, [status]);
    return (
        <AuthUITemplate title="Reset Password" errorMsg={error}>
            <form className="row g-3" onSubmit={(e) => handleSubmit(e)}>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder="Email"
                            name="email"
                            onChange={(e) => handleChange(e)}
                            value={email}
                            required
                        />
                        <label for="email">Email address</label>
                    </section>
                </section>

                <section className="col-12 d-grid">
                    <button
                        type="submit"
                        className={
                            loading
                                ? "btn btn-primary disabled"
                                : "btn btn-primary"
                        }
                    >
                        {loading ? <LoaderIcon /> : null}
                        Reset Password
                    </button>
                </section>
            </form>
            <section class="mt-3">
                <p class="text-center">
                    Already have an account?{" "}
                    <Link to="/login" class="fw-bold text-decoration-none">
                        Log In
                    </Link>
                </p>
            </section>
        </AuthUITemplate>
    );
};

export default ResetPassword;
