import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import axios from "axios";
import LoaderIcon from "./utilities/loader";
import SuccessModal from "./utilities/successModalMsg";

const OnlineCourseRegModal = ({ show, onClose, is_track, regInfo }) => {
    return (
        <>
            <Modal
                show={show}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
                </Modal.Header>

                <Modal.Body className="p-4">
                    <h3 className="text-center">Coming Soon!!!</h3>

                    <section className="d-flex justify-content-center mt-5">
                        <Button
                            variant="btn btn-outline-danger"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default OnlineCourseRegModal;
