import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLoggedInUserPassword, clearErrors } from "../actions/auth";

const useChangeAuthenticatedUserPassword = (initialFields, apiUrl) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(initialFields);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const authError = useSelector((state) => state.auth.error);
    const status = useSelector((state) => state.auth.status);

    useEffect(() => {
        // Clear errors when the component unmounts
        return () => {
            dispatch(clearErrors());
        };
    }, [dispatch]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prevFields) => ({ ...prevFields, [name]: value }));
    }, []);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();

            const { current_password, new_password, confirm_new_password } =
                formData;

            if (new_password !== confirm_new_password) {
                setError("New passwords do not match.");
                return;
            }

            setError(null);
            setLoading(true);

            try {
                await dispatch(
                    changeLoggedInUserPassword(
                        { current_password, new_password },
                        apiUrl
                    )
                );
            } catch (err) {
                console.error("Error submitting form", err);
            } finally {
                setLoading(false);
            }
        },
        [dispatch, formData, apiUrl]
    );

    return {
        loading,
        formData,
        error: error || authError,
        onChange: handleChange,
        onSubmit: handleSubmit,
        status,
    };
};

export default useChangeAuthenticatedUserPassword;
