import { useContext, useEffect } from "react";
import PageTitle from "../pageTitle";
import ContactForm from "../../cards/contactForm";
import MailSubscription from "../../cards/emailSub";
import { Helmet } from "react-helmet-async";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import BlogCard from "../../cards/blogCard";

const Blog = () => {
    return (
        <>
            <Helmet>
                <title>Blog - Codehub Technologies</title>
            </Helmet>
            <PageTitle title="Our Blog" />
            <section className="container py-10">
                <section className="row">
                    <section className="col-md-8">
                        <section className="row row-cols-1 row-cols-lg-2 g-6 justify-content-center">
                            <BlogCard />
                            <BlogCard />
                            <BlogCard />
                            <BlogCard />
                            <BlogCard />
                            <BlogCard />
                            <BlogCard />
                            <BlogCard />
                        </section>
                    </section>
                    <section className="col-md-4 bg-light align-self-start">
                        <section>
                            <h6>Categories</h6>
                            <section></section>
                        </section>

                        <section>
                            <h6>Tags</h6>
                        </section>

                        <section>
                            <h6>Achieves</h6>
                            <section></section>
                        </section>
                    </section>
                </section>
            </section>

            <MailSubscription />
            <ContactForm />
        </>
    );
};

export default Blog;
