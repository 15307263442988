const StudentDashboardFooter = () => {
    const date = new Date();
    return (
        <section className="text-center p-4 bg-light mt-8">
            <small>
                &#169; {date.getFullYear()} Codehub Technologies. All rights
                Reserved
            </small>
        </section>
    );
};

export default StudentDashboardFooter;
