import AuthUITemplate from "./AuthUI";
import LoaderIcon from "../../cards/utilities/loader";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import useActivate from "../../../hooks/useActivate";

const apiURL = process.env.REACT_APP_API_URL;

const ActivateAccount = () => {
    const [verified, setVerified] = useState(false);

    const { uid } = useParams();
    const { token } = useParams();
    const navigate = useNavigate();

    const { error, loading, onSubmit, status } = useActivate(
        uid,
        token,
        apiURL
    );

    useEffect(() => {
        if (status === 204) {
            // handleShow()
        }
    }, [status]);

    // setTimeout(() => {
    //     if (show) {
    //         return navigate('/login')
    //     }
    // }, 5000)
    return (
        <AuthUITemplate title="Activate your Account" errorMsg={error}>
            <section className="col-12 d-grid">
                <button
                    onClick={onSubmit}
                    type="submit"
                    className={
                        loading ? "btn btn-primary disabled" : "btn btn-primary"
                    }
                >
                    {loading && <LoaderIcon />}
                    Click here to Verify your Account
                </button>
            </section>
        </AuthUITemplate>
    );
};

export default ActivateAccount;
