import React, { useState } from "react";
import Table from "react-bootstrap/Table";

const pic = {
    url: "http://www.highreshdwallpapers.com/wp-content/uploads/2014/01/Athletic-Running-HD-Image.jpg",
};

const PaymentCart = () => {
    const rows = [
        {
            id: 1,
            firstName:
                "This is the course/track title which is very long and really really really long",
            lastName: "Otto",
            username: "N32,000",
        },
        {
            id: 2,
            firstName: "Jacob",
            lastName: "Thornton",
            username: "N16,000",
        },
        {
            id: 3,
            firstName: "Larry the Bird",
            lastName: "",
            username: "N150,000",
        },
    ];
    return (
        <section className="container">
            <section className="row g-3">
                <section className="col-lg-8 align-self-start">
                    <section className="card no-card-style p-4">
                        <header>
                            <h5 style={{ display: "inline-block" }}>
                                Payment Cart{" "}
                                <span
                                    class="badge rounded-pill bg-danger"
                                    style={{
                                        border: "2px solid white",
                                    }}
                                >
                                    2{" "}
                                    <span class="visually-hidden">
                                        shopping cart
                                    </span>
                                </span>
                            </h5>
                            <p>Review all your payment Cart</p>
                        </header>

                        <Table bordered hover>
                            <thead>
                                <tr className="bg-primary text-white text-center">
                                    <th>Course/Track</th>

                                    <th className="d-none d-sm-table-cell">
                                        Actions
                                    </th>
                                    <th className="d-none d-sm-table-cell">
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row, index) => (
                                    <React.Fragment key={row.id}>
                                        <tr>
                                            <td>
                                                <section className="d-flex">
                                                    <img
                                                        src={pic.url}
                                                        className="align-self-start me-1"
                                                        width="100"
                                                        // height="100"
                                                        alt=""
                                                    />
                                                    <section>
                                                        <h6>{row.firstName}</h6>

                                                        <section
                                                            className="fw-bold bg-light px-2 py-1 rounded"
                                                            style={{
                                                                fontSize:
                                                                    "13px",
                                                            }}
                                                        >
                                                            <span>
                                                                <i class="fa-solid fa-user"></i>{" "}
                                                                <small className="me-2">
                                                                    level
                                                                </small>
                                                            </span>

                                                            <span>
                                                                <i class="fa-solid fa-star"></i>{" "}
                                                                <small className="me-2">
                                                                    4.8 (132k
                                                                    Reviews)
                                                                </small>
                                                            </span>
                                                            <span>
                                                                <i class="fa-solid fa-graduation-cap"></i>{" "}
                                                                <small className="me-2">
                                                                    Professional
                                                                    Certificate
                                                                </small>
                                                            </span>

                                                            <span>
                                                                <i class="fa-regular fa-clock"></i>{" "}
                                                                <small>
                                                                    duration
                                                                </small>
                                                            </span>
                                                        </section>
                                                    </section>
                                                </section>
                                            </td>
                                            <td className="d-none d-sm-table-cell text-center">
                                                <i
                                                    class="fa-solid fa-trash text-danger"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                ></i>
                                                {/* <i class="fa-solid fa-star "></i> */}
                                            </td>
                                            <td className="d-none d-sm-table-cell ">
                                                <p className="fw-bold text-primary">
                                                    {row.username}
                                                </p>
                                                <p>
                                                    <s>{row.username}</s>
                                                </p>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </section>
                </section>

                <section className="col-lg-4 align-self-start">
                    <section className="card no-card-style p-4 mb-3">
                        <p>Total</p>
                        <h5>N33,000</h5>
                        <p>
                            <s>N15,000</s>
                        </p>
                        <button className="btn btn-primary">Checkout</button>
                    </section>

                    <section className="card no-card-style p-4">
                        <h5>Instruction for Offline Payments</h5>
                        <p>Choose a transaction to download the Reciept</p>
                    </section>
                </section>
            </section>
        </section>
    );
};

export default PaymentCart;
