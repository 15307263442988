import Nav from "react-bootstrap/Nav";
import { useState } from "react";
import HeaderBar from "./components/headerBar";
import CompletedCourses from "./Courses/completedCourses";
import InProgressCourses from "./Courses/inProgressCourse";

const MyCourses = () => {
    const [activeTab, setActiveTab] = useState("#in-progress");

    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    return (
        <section className="">
            <HeaderBar pageHeader="My Courses">
                <Nav
                    variant="pills"
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    className=""
                >
                    <Nav.Item className="border border-2 border-primary me-2">
                        <Nav.Link eventKey="#in-progress">In Progress</Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="border border-2 border-primary">
                        <Nav.Link eventKey="#completed">Completed</Nav.Link>
                    </Nav.Item>
                </Nav>
            </HeaderBar>
            <section className="container">
                {activeTab === "#in-progress" && <InProgressCourses />}

                {activeTab === "#completed" && <CompletedCourses />}
            </section>
        </section>
    );
};

export default MyCourses;
