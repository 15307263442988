import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import SearchForm from "../../../cards/utilities/searchForm";
import Form from "react-bootstrap/Form";
import usePagination from "../../../../hooks/usePagination";

const PaymentHistory = () => {
    const [expandedRow, setExpandedRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const toggleRow = (rowIndex) => {
        setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
    };

    const rows = [
        { id: 1, firstName: "Mark", lastName: "Otto", username: "@mdo" },
        { id: 2, firstName: "Jacob", lastName: "Thornton", username: "@fat" },
        {
            id: 3,
            firstName: "Larry the Bird",
            lastName: "",
            username: "@twitter",
        },
    ];

    // Handle individual row selection
    const handleRowSelect = (row) => {
        const isSelected = selectedRows.some(
            (selectedRow) => selectedRow.id === row.id
        );

        const newSelectedRows = isSelected
            ? selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
            : [...selectedRows, row];

        setSelectedRows(newSelectedRows);

        // Update the "select all" checkbox state
        if (newSelectedRows.length === rows.length) {
            setSelectAllChecked(true);
        } else {
            setSelectAllChecked(false);
        }
    };

    // Handle "select all" checkbox
    const handleSelectAll = () => {
        if (selectAllChecked) {
            setSelectedRows([]);
        } else {
            setSelectedRows([...rows]);
        }
        setSelectAllChecked(!selectAllChecked);
    };
    return (
        <section className="container">
            <section className="row g-3">
                <section className="col-lg-8 align-self-start">
                    <section className="card no-card-style p-4">
                        <header>
                            <h5>Payment History</h5>
                            <p>Review all your transaction history</p>

                            <section className="w-100 mb-4">
                                <SearchForm placeholder="Search Transactions" />
                            </section>
                        </header>

                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>
                                        <Form.Check
                                            type={"checkbox"}
                                            id="checkbox"
                                            checked={selectAllChecked}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th>S/N</th>
                                    <th>Course</th>
                                    <th className="d-none d-sm-table-cell">
                                        Track
                                    </th>
                                    <th className="d-none d-sm-table-cell">
                                        Payment Date
                                    </th>
                                    <th className="d-none d-sm-table-cell">
                                        Amount
                                    </th>
                                    <th></th>{" "}
                                    {/* Add a new header for the expand/collapse arrow */}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row, index) => (
                                    <React.Fragment key={row.id}>
                                        <tr>
                                            <td>
                                                <Form.Check
                                                    type={"checkbox"}
                                                    id="checkbox"
                                                    checked={selectedRows.some(
                                                        (selectedRow) =>
                                                            selectedRow.id ===
                                                            row.id
                                                    )}
                                                    onChange={() =>
                                                        handleRowSelect(row)
                                                    }
                                                />
                                            </td>
                                            <td>{row.id}</td>
                                            <td>{row.firstName}</td>
                                            <td className="d-none d-sm-table-cell">
                                                {row.lastName}
                                            </td>
                                            <td className="d-none d-sm-table-cell">
                                                {row.username}
                                            </td>
                                            <td className="d-none d-sm-table-cell">
                                                {row.username}
                                            </td>
                                            <td
                                                onClick={() => toggleRow(index)}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {/* Render the arrow icon conditionally */}
                                                {expandedRow === index ? (
                                                    <FaChevronUp />
                                                ) : (
                                                    <FaChevronDown />
                                                )}
                                            </td>
                                        </tr>
                                        {expandedRow === index && (
                                            <tr>
                                                <td colSpan="5">
                                                    <div>
                                                        {/* Replace this with whatever content you want to display */}
                                                        <p>
                                                            Additional details
                                                            for {row.firstName}{" "}
                                                            {row.lastName}.
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </section>
                </section>

                <section className="col-lg-4 align-self-start">
                    <section className="card no-card-style p-4">
                        <h5>Transaction Reciept</h5>
                        <p>Choose a transaction to download the Reciept</p>

                        <section>
                            {Object.keys(selectedRows).length > 0 && (
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Course</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedRows.map((row, index) => (
                                            <React.Fragment key={row.id}>
                                                <tr>
                                                    <td>{row.firstName}</td>
                                                    <td>{row.lastName}</td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                        <tr>
                                            <td>Total</td>
                                            <td>N10,000</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            )}
                        </section>
                        <button
                            className={`btn btn-primary ${
                                Object.keys(selectedRows).length === 0 &&
                                `disabled`
                            } `}
                        >
                            Download Transaction Reciept
                        </button>
                    </section>
                </section>
            </section>
        </section>
    );
};

export default PaymentHistory;
