import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import axios from "axios";
import LoaderIcon from "./utilities/loader";
import SuccessModal from "./utilities/successModalMsg";

const OnsiteCourseRegModal = ({ show, onClose, is_track, regInfo }) => {
    //Success Modal
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handleSuccessModalClose = () => {
        // setFormError([])
        setShowSuccessModal(false);
    };
    const handleSuccessModalShow = () => setShowSuccessModal(true);

    // Form
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState([]);
    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        phone_number: "",
        address: "",
    });

    const { full_name, email, phone_number, address } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        // declare the data fetching function
        const PostFormData = async () => {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            };

            const body = new FormData();
            body.append("full_name", full_name);
            body.append("email", email);
            body.append("phone_number", phone_number);
            body.append("address", address);
            is_track
                ? body.append("track", regInfo.id)
                : body.append("course", regInfo.id);

            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/course/onsite-enrollment`,
                    body,
                    config
                );
                setLoading(false);
                if (res.status === 201) {
                    onClose();
                    handleSuccessModalShow();
                    setFormData({
                        full_name: "",
                        email: "",
                        phone_number: "",
                        address: "",
                    });
                }
            } catch (err) {
                setLoading(false);
                setFormError(err.response.data);
            }
        };
        PostFormData();
    };
    return (
        <>
            <Modal
                show={show}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                size="xl"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <section className="container">
                        <section className="row g-4">
                            <section className="col-md-5 bg-light py-3">
                                <h4>Please take note:</h4>
                                <ol>
                                    <li>
                                        Fees payment: Please note that you will
                                        not be eligible for admission until the
                                        appropriate Tuition Fees are paid.{" "}
                                    </li>

                                    <li>
                                        Bank Transfer: Moniepoint Bank.{" "}
                                        <span className="fw-bold">
                                            Account Number: 9072444299. Account
                                            Name: Codehub Technologies.
                                        </span>{" "}
                                        After payment, send proof of payment to
                                        09072444299 on WhatsApp for proper
                                        documentation.
                                    </li>
                                    <li>
                                        Cash/Cheque payments are not acceptable
                                    </li>
                                    <li>
                                        Installment payments are possible, upon
                                        paying Registration Fees and contacting
                                        Admissions on 09072444299.
                                    </li>
                                </ol>
                            </section>

                            <section className="col-md-7">
                                <h4>
                                    Onsite {is_track ? "Track" : "Course"}{" "}
                                    Registration
                                </h4>
                                <p>
                                    {is_track ? "Track:" : "Course:"}{" "}
                                    {regInfo.title}
                                </p>
                                <form
                                    className="row g-3"
                                    onSubmit={(e) => onSubmit(e)}
                                >
                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="full_name"
                                                placeholder="Full Name"
                                                name="full_name"
                                                onChange={(e) => onChange(e)}
                                                value={full_name}
                                                required
                                            />
                                            <label for="full_name">
                                                Full Name
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="phone_number"
                                                placeholder="Phone Number"
                                                name="phone_number"
                                                onChange={(e) => onChange(e)}
                                                value={phone_number}
                                                required
                                            />
                                            <label for="phone_number">
                                                Phone Number
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="Email"
                                                name="email"
                                                onChange={(e) => onChange(e)}
                                                value={email}
                                                required
                                            />
                                            <label for="email">Email</label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <textarea
                                                class="form-control"
                                                id="floatingTextarea2"
                                                rows="4"
                                                placeholder="Enter Home Address"
                                                name="address"
                                                onChange={(e) => onChange(e)}
                                                value={address}
                                                style={{ height: "9.375rem" }}
                                                required
                                            ></textarea>
                                            <label for="floatingTextarea2">
                                                Home Address
                                            </label>
                                        </div>
                                    </div>

                                    <section className="d-flex justify-content-center mt-5">
                                        <Button
                                            variant="btn btn-outline-danger me-5"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </Button>

                                        <button
                                            type="submit"
                                            className={
                                                loading
                                                    ? "btn btn-primary disabled"
                                                    : "btn btn-primary"
                                            }
                                        >
                                            {loading && <LoaderIcon />}
                                            Submit
                                        </button>
                                    </section>
                                </form>
                            </section>
                        </section>
                    </section>
                </Modal.Body>
            </Modal>
            {showSuccessModal && (
                <SuccessModal
                    message={`You have Sucessfully enrolled for ${
                        regInfo.title
                    } ${
                        is_track ? "Track" : "Course"
                    }. Kindly check your email for further Instructions/Procedures. Thank you`}
                    errorMessage={formError}
                    show={showSuccessModal}
                    onClose={handleSuccessModalClose}
                />
            )}
        </>
    );
};

export default OnsiteCourseRegModal;
