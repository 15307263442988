import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../actions/auth";

const useLogout = () => {
    const dispatch = useDispatch();

    const handleLogout = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    return handleLogout;
};

export default useLogout;
