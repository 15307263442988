import AuthUITemplate from "./AuthUI";
import { useEffect, useState } from "react";
import LoaderIcon from "../../cards/utilities/loader";
import { useParams, useNavigate } from "react-router-dom";
import usePasswordConfirm from "../../../hooks/usePasswordConfirm";
import HideShowPassword from "./hideShowPassword";

const apiURL = process.env.REACT_APP_API_URL;

const NewPasswordConfirm = () => {
    const { uid } = useParams();
    const { token } = useParams();
    const navigate = useNavigate();

    // Initial Data
    const initialFormData = {
        uid: uid,
        token: token,
        new_password: "",
        re_new_password: "",
    };

    const [hideNewPassword, setHideCNewPassword] = useState(false);
    const [hideReNewPassword, setHideReNewPassword] = useState(false);

    const { error, status, formData, loading, onChange, onSubmit } =
        usePasswordConfirm(initialFormData, apiURL);

    useEffect(() => {
        if (status === 204) {
            // handleShow()
        }
    }, [status]);

    // setTimeout(() => {
    //     if (show) {
    //         return navigate('/login')
    //     }
    // }, 5000)
    return (
        <AuthUITemplate title="Enter New Password" errorMsg={error}>
            <form className="row g-3" onSubmit={(e) => onSubmit(e)}>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type={hideNewPassword ? "text" : "password"}
                            class="form-control position-relative pe-7"
                            id="new_password"
                            placeholder="Password"
                            name="new_password"
                            onChange={(e) => onChange(e)}
                            value={formData.new_password}
                            required
                        />
                        <HideShowPassword
                            hidePassword={hideNewPassword}
                            setHidePassword={setHideCNewPassword}
                        />
                        <label for="password">New Password</label>
                    </section>
                </section>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type={hideReNewPassword ? "text" : "password"}
                            class="form-control position-relative pe-7"
                            id="re_new_password"
                            placeholder="Confirm Password"
                            name="re_new_password"
                            onChange={(e) => onChange(e)}
                            value={formData.re_new_password}
                            required
                        />
                        <HideShowPassword
                            hidePassword={hideReNewPassword}
                            setHidePassword={setHideReNewPassword}
                        />
                        <label for="re_password">Confirm New Password</label>
                    </section>
                </section>

                <section className="col-12 d-grid">
                    <button
                        type="submit"
                        className={
                            loading
                                ? "btn btn-primary disabled"
                                : "btn btn-primary"
                        }
                    >
                        {loading ? <LoaderIcon /> : null}
                        Change Password
                    </button>
                </section>
            </form>
        </AuthUITemplate>
    );
};

export default NewPasswordConfirm;
